<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col
        cols="12" sm="7" md="7" lg="7"
         class="pb-1 d-flex align-center"
      >
        <span class="headline text-capitalize font-weight-bold primary--text" v-text="$route.name"></span> 
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
        md="5" lg="5" sm="5"
      >
        <v-text-field
            class=""
            hide-details
            single-line
            outlined
            rounded
            dense
            color="primary"
            placeholder="Buscar usuarios"
            v-model="search"
          >
            <template v-slot:prepend-inner>
              <v-icon color="info">mdi-magnify</v-icon>
            </template>
          </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0">
          <v-card-title class="pl-0">
            <v-row>
              <v-col col="6" class="pl-0"> 
                <v-btn rounded small depressed color="sidebar" class="ml-2" style="padding-left: 20px !important; padding-right: 20px !important" @click="modal_user = true">
                  <v-icon small left>mdi-account-plus</v-icon>
                  Nuevo
                </v-btn>
                <v-menu offset-y v-if="$vuetify.breakpoint.smAndUp">
                  <template v-slot:activator="{ on }">
                    <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                      <v-icon small>mdi-filter</v-icon>
                      Filtrar
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                      <v-list-item @click="sort_user = 'username'">
                        <v-list-item-title>Nombre</v-list-item-title>
                      </v-list-item>                
                  </v-list>
                </v-menu>
                  <v-btn rounded small outlined class="ml-4" color="info" style="padding-left: 20px !important; padding-right: 20px !important" v-if="error_serve" @click="getUsers">
                    <v-icon small left>mdi-reload</v-icon>
                    Recargar lista
                  </v-btn>                     
              </v-col>
            </v-row>
          </v-card-title>
          <v-sheet class="" v-if="load_user">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>      
            <v-data-table
            v-else
            :headers="headers_filters"
            :items="users"
            class="elevation-0 striped"
            :search="search"
            no-data-text="No se encontraron Usuarios"
            no-results-text="No se encontraron Coincidencias"
            single-select
            show-select
            fixed-header
            :sort-by.sync="sort_user"
            item-key="id"
            loading-text="Obteniendo Usuarios"
            v-model="selected_user"
          >
           <!--  <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer 
                :pagination="pagination" 
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
            </template> -->
            <template v-slot:header.image>
              <v-icon color="blue-grey lighten-3">mdi-account-circle</v-icon>
            </template>
            <template v-slot:item.image="">
                <v-avatar color="blue-grey lighten-3" size="28">
                  <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
              </template>
            <template v-slot:item.username="{ item }">
              <span class="font-weight-bold text-uppercase">{{ item.username }}</span>
            </template>
            <template v-slot:item.status="{ item }">

                <v-chip class="my-2" dark :color="item.status == 0 ? 'blue-grey' : 'success'" small v-text="item.status == 0 ? 'Inhabilidato' : 'Habilitado'"></v-chip>               

            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="actionUpdate(item)" v-if="$can('Editar usuario')">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                 <v-btn icon color="primary lighten-1"  @click="deleteUser(item)" v-if="$can('Borrar usuario')">
                  <v-icon color="primary lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn> 
                <v-btn icon @click="changeStatus(item)">
                  <v-icon :color="item.status == 0 ? 'blue-grey' : 'cyan'" v-text="item.status == 0 ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"></v-icon>
                </v-btn>                 
              </div>
            </template>
          </v-data-table>            
        </v-card>
      </v-col>
    </v-row>
    <!-- *******MODAL DE CREAR/EDITAR USUARIO****** -->
    <base-material-modal v-model="modal_user" icon="mdi-account" :loadcard="saving_user" :title="action_user + ' Usuario'" @hidde="resetUser">
      <template v-slot:content>
        <validation-observer ref="observerUser" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Usuario" vid="username" rules="required" v-slot="{ errors }">
                <v-text-field outlined label="Nombre Usuario*" prepend-inner-icon="mdi-account-circle" v-model="formUser.username" :error-messages="errors[0]"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Contraseña" :rules="{'required': action_user != 'Actualizar'}" vid="confirmation" v-slot="{ errors }">
                <v-text-field outlined :label="action_user === 'Crear' ? 'Contraseña*' : 'Nueva Contraseña' " :type="show ? 'text' : 'password'" prepend-inner-icon="mdi-lock" v-model="formUser.password" required placeholder="" :error-messages="errors[0]" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :hint="action_user === 'Crear' ? '' : 'Si no desea cambiar la Contraseña dejar el campo vacío.' " :persistent-hint="action_user === 'Actualizar'"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0" v-if="$store.getters['auth/rol'] != 'Dueño restaurante'">
              <validation-provider name="rol" rules="required" v-slot="{ errors }">
                <v-select
                  :items="rolesUsers"
                  label="Rol de Usuario*"
                  name="Rol"
                  outlined
                  append-inner-icon="mdi-plus"
                  prepend-inner-icon="mdi-account-cog"
                  :error-messages="errors[0]"
                  v-model="formUser.rol"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0" v-if="$store.getters['auth/rol'] != 'Dueño restaurante' && formUser.rol === 'Empleados'">
              <validation-provider name="rol" rules="required" v-slot="{ errors }">
                <v-select
                  :items="restaurants"
                  label="Restaurante*"
                  name="Restaurante"
                  outlined
                  append-inner-icon="mdi-plus"
                  prepend-inner-icon="mdi-account-cog"
                  :error-messages="errors[0]"
                  v-model="formUser.restaurant_id"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
        <v-overlay absolute :value="overlay_edit" :opacity="0.95" :z-index="100" color="white" class="text-center">
          <v-progress-circular color="primary" indeterminate size="44"></v-progress-circular>
          <h5 class="blue-grey--text mt-4">Obteniendo datos...</h5>
        </v-overlay>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed 
            small
            @click="resetUser"
            :disabled="saving_user"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_user" @click="validateUser">
            {{ action_user }}
          </v-btn>
      </template>   
    </base-material-modal>
    
    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
      right
    >
      {{ snackbar.text }}
    </base-material-snackbar>    
    <v-dialog v-model="load_delete" persistent width="300">
      <v-card color="info" dark>
        <v-card-text>
          <span class="white--text font-weight mb-2">
            <v-icon>mdi-trash-can-outline</v-icon>
            Eliminando usuario
          </span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'Users',
    data () {
      return {
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        search:'',
        selected_user: [],
        load_user: false,
        saving_user: false,
        error_serve: false,
        overlay_edit: false,
        show: false,
        load_delete: false,
        users: [],
        roles:[],
        restaurants: [],
        sort_user: '',
        headers: [
          {
            text: '',
            value: 'image',
            sortable: false,
            width: '60',
            align: 'center',
          },
          { text: 'Nombre de usuario', value: 'username' },
          { text: 'Estatus', value: 'status', width: '120' },
          { text: 'Rol', value: 'roles[0].name' },
          { text: '', value: 'actions', width: '200' },
        ],
        not_include:[],
        formUser:{
          username: '',
          password: '',
          rol: '',
          restaurant_id: '',
        },
        id_user: '',
        action_user: 'Crear',
        modal_user: false
      }
    },
    created(){
      this.getUsers();
      if(this.$store.getters['auth/rol'] === "Dueño restaurante"){
        this.formUser.rol = "Empleados";
        this.not_include.push('roles[0].name');
      }
      else{
        this.getRoles();
        this.not_include = [];        
      }
    },
    methods: {
      async getUsers(){
        this.load_user= true;
        this.users = [];
        try{
          const response = await this.$api.get('api/users');
          const objectArray = Object.entries(response.data.data.users);
          objectArray.forEach(([key, value]) => {
              this.b = key;
              this.users.push(value);            
          });

          this.load_user= false;
          this.error_serve= false;        
        } 
        catch(error){
          this.load_user= false;
          this.error_serve= true;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener los usuarios',
            type: 'error',
            active:true,
          };
          
        }
      },
      async getRoles(){
        const { data } = await this.$api.get('api/users/create');
        this.roles = data.data.roles;

        this.restaurants = data.data.restaurants.map(rest =>{
          const item = {
            text: rest.name,
            value: rest.id
          };
          return item;
        });
       
      },
      resetUser(){
        this.modal_user = false;
        setTimeout(() => {
          this.id_user = '';
          this.action_user = "Crear";
          this.formUser.username = '';
          this.formUser.password = '';
          this.formUser.rol = '';
          this.formUser.restaurant_id = '';
        }, 1000)
         
        this.selected_user = [];
        this.$refs.observerUser.reset();
      },
      validateUser(){
        this.$refs.observerUser.validate().then(result =>{
          if(result){
            this.actionsUser();
          }
        });
      },
      async actionsUser(){
        this.saving_user = true;
        const url = this.action_user == 'Crear' ? 'api/users' : `api/users/${this.id_user}`;
        const method = this.action_user == 'Crear' ? 'post' : 'put';

        try{
          const send_user = await this.$api({
            url: url,
            method: method,
            data: this.formUser
          });        
          this.resetUser();
          this.getUsers();
          this.saving_user = false;
          this.snackbar = {
            text: send_user.data.message,
            type: 'success',
            active: true            
          }
        }
        catch(e){
          if(e.response.status == "422"){
            this.$refs.observerUser.setErrors(e.response.data.data);            
          }
          else{
            this.snackbar = {
              text:  e.response.data.message,
              type: 'error',
              active: true            
            }            
          }
          this.saving_user = false;
        }
      },
      async actionUpdate(item){
        this.id_user = item.id;
        this.action_user = 'Actualizar';
        const url = `api/users/${this.id_user}/edit`;
        this.modal_user = true;
        this.overlay_edit = true;
        try{
          const { data } = await this.$api.get(url);
          this.formUser = {
            username: data.data.user.username,
            rol: data.data.user.roles[0].name,
            restaurant_id: data.data.user.restaurants.length > 0 ? data.data.user.restaurants[0].id : null 
          } 
          this.overlay_edit = false;
        }
        catch(e){     
          this.modal_user = false;     
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true            
          }
        }
      },
      async deleteUser(item){
        const msj = "Se eliminaran permanentemente los datos del usuario " + item.username;

        const confirm = await this.$root.$confirm('¿Estas Seguro de Eliminar?',msj, { type: 'delete', btnConfirmText: 'Si, Eliminalo', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_delete= true;
          const url = 'api/users/' + item.id;
          try{
            const {data} = await this.$api.delete(url);         
            this.load_delete= false;
            this.getUsers();
            this.search = '';
            this.snackbar = {
              text: data.message,
              type: 'success',
              active: true            
            }
          }
          catch(error){
            this.load_delete= false;
            if(error.response.status == "400"){
              this.snackbar = {
                text: error.response.data.message,
                type: 'error',
                active: true            
              }    
            }
            else{
              this.snackbar = {
                text: 'Lo sentimos, hubo un error al intentar eliminar el Usuario.',
                type: 'error',
                active: true            
              }              
            }
          }
        }
      },
      async changeStatus(item){
        const status = item.status == 1 ? 'Inhabilitado' : 'Habilitado';
        const msj = `Se cambiara el estatus del usuario ${item.username} a ${status}`;

        const confirm = await this.$root.$confirm('¿Estas Seguro?',msj, { type: 'confirm', btnConfirmText: 'Si, Cambiar', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_user= true;
          const url = 'api/users/changestatus';
          try{
            const {data} = await this.$api.post(url, {user_id: item.id, status: item.status == 1 ? 0 : 1});         
            this.load_user= false;
            this.getUsers();
            this.search = '';
            this.snackbar = {
              text: data.message,
              type: 'success',
              active: true            
            }
          }
          catch(error){
            this.load_delete= false;
            if(error.response.status == "400"){
              this.snackbar = {
                text: error.response.data.message,
                type: 'error',
                active: true            
              }    
            }
            else{
              this.snackbar = {
                text: 'Lo sentimos, hubo un error al intentar cambiar el Estatus.',
                type: 'error',
                active: true            
              }              
            }
          }
        }
      },
    },
    computed:{
      headers_filters(){
        return this.headers.filter(head => !this.not_include.includes(head.value));
      },
      rolesUsers(){
        if(this.action_user === "Crear"){
          return this.roles.filter(rol => rol.name !== "Dueño restaurante").map(rol =>{
            const item = {
              text: rol.name,
              value: rol.name
            };
            return item;
          });
        }

        if(this.action_user === "Actualizar"){
           return this.roles.map(rol =>{
            const item = {
              text: rol.name,
              value: rol.name
            };
            return item;
          });
        }

        return [];
      }
    }
  }
</script>
